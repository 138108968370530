import React from "react"
import { Link, graphql } from "gatsby"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
`

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="About" />
      <Content>
        {data.allMarkdownRemark.edges
          .filter(({ node }) => {
            const rawDate = node.frontmatter.rawDate
            const date = new Date(rawDate)
            return date < new Date()
          })
          .map(({ node }) => (
            <div key={node.id}>
              <Link
                to="/aboutme"
                css={css`
                  text-decoration: none;
                  color: inherit;
                `}
              ></Link>
            </div>
            //content to add
          ))}
      </Content>
      <div className="connectionlinks">
        <p>
          <a href="https://www.linkedin.com/in/rethabile-segoe-35559916b/">
            LinkedIn
          </a>{" "}
          <a href="https://github.com/lalawuhan">GitHub</a>{" "}
          <a href="https://dev.to/thabisegoe">Dev</a>
        </p>
      </div>
      <div className="background-linkimg"></div>
      <div className="backgroundborderimg"></div>

      <div className="introphoto">
        <img
          src="https://i1.lensdump.com/i/jIS7jH.jpg"
          alt="authorsphoto"
        ></img>
        <p>
          <span className="nameintro"> Rethabile Segoe</span>
          <br></br> I am originally from South Africa. I studied Visual
          Communications Design in China and recently moved to Berlin. I love
          travel, adventure and meeting new people. I am used to being outside
          of my comfort zone. <br></br>I consider my curious mind and
          communication skills as two of my biggest strengths. You will usually
          find me spending my downtime researching something. I like to share
          what I have learnt with others around me and vice versa. I would
          thrive in an environment that has the space to share knowledge and
          contribute new ideas.
        </p>
      </div>

      <div className="locationbase">
        <div className="sidecol">
          <p>
            <b>Based in:</b> Berlin, Germany
          </p>
          <img src="https://i.lensdump.com/i/jISOq1.png" alt="location"></img>
        </div>
        <p className="mainlocationtext">
          <i
            css={css`
              font-weight: 200;
              line-height: 1.4;
              text-align: center;
            `}
          >
            I like React.js, accessible design and great user experiences. I am
            always learning.
          </i>
        </p>
      </div>
      <div className="lineup"></div>
      <h3
        css={css`
            text-align:left;
            color:#303030	;
          }
        `}
      >
        Experience and Education
      </h3>
      <div className="timelinecontainer">
        <div className="timeline-item" date-is="June 2020 - Currently">
          <h1>
            Developer, <a href="https://www.mad.ac/">MAD</a>
          </h1>
        </div>
        <div className="timeline-item" date-is="Feb 2020 - Apr 2020">
          <h1>Student, Spiced Academy</h1>
          <p>
            The Full Stack Web Development program covers JavaScript, HTML, CSS,
            jQuery, MVC Frameworks, NodeJS, and SQL.
          </p>
        </div>
        <div className="timeline-item" date-is="Dec 2019 - Feb 2020">
          <h1>Front-End Web Developer Nanodegree, Udacity</h1>
          <p>
            The Full Stack Web Development program covers JavaScript, HTML, CSS,
            jQuery, MVC Frameworks, NodeJS, and SQL.
          </p>
        </div>
        <div className="timeline-item" date-is="Sep 2019 - Dec 2019">
          <h1>Web Developer Trainee, Frauenloop</h1>
          <p>
            Sessions hosted at Microsoft Berlin and guided by a mentor team.
            Weekly evening course that covered the basics of Python, Git, HTML
            and CSS and Bootstrap4.
          </p>
        </div>
        <div className="timeline-item" date-is="Oct 2019 - Nov 2019">
          <h1>JS Crash Course, Women Techmakers Berlin</h1>
          <p>
            Course designed for introducing JavaScript and Node.js as a platform
            for backend applications. We covered topics like ES6 Fundamentals,
            Node.js ecosystem, Promises, Async Programming, HTTP APIs with
            Express.js, Testing and deploying with Docker.
          </p>
        </div>
        <div className="timeline-item" date-is="Mar 2018 - Jul 2018">
          <h1>Intern, 小赞武汉 Wuhan Social</h1>
          <p>
            Course designed for introducing JavaScript and Node.js as a platform
            for backend applications. We covered topics like ES6 Fundamentals,
            Node.js ecosystem, Promises, Async Programming, HTTP APIs with
            Express.js, Testing and deploying with Docker.
          </p>
        </div>
        <div className="timeline-item" date-is="Sep 2014 - Jul 2019">
          <h1>Visual Communications Design, Central China Normal University</h1>
          <p>
            I am often asked what a "normal university" means. Normal University
            refers to an institution that aimed to train school teachers in the
            early twentieth century. This terminology is preserved in the
            official names of such institutions in China even after these
            schools gained university status and expanded to offer courses
            beyond education-related fields. CCNU is ranked as a top 3 normal
            university in Mainland China.
          </p>
          <ul>
            <li>
              Challenging degree that was taught in Mandarin which helped me
              grow my interpersonal skills, work in a team and learn from
              others.
            </li>
            <li>
              I gained experience in industry-standard software such as Sketch,
              Adobe Creative Cloud applications (Illustrator, Photoshop,
              InDesign).
            </li>
            <li>
              We had projects that focused on visual design, UI design,
              interaction design, and product design.
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="experience-section">
        <h3>Student, Spiced Academy</h3>
        <p>
          <span className="emphasistext">
            12 week intensive Web Development Course.
          </span>
          The Full Stack Web Development program covers JavaScript, HTML, CSS,
          jQuery, MV Frameworks, NodeJS, and SQL.
        </p>

        <h3>Web Developer Trainee, Frauenloop</h3>
        <p>
          Sessions hosted at Microsoft Berlin and guided by a mentor team.
          Weekly evening course that covered the basics of Python, Git, HTML and
          CSS and Bootstrap4.
        </p>

        <h3>JS Crash Course, Women Techmakers Berlin</h3>
        <p>
          Course designed for introducing JavaScript and Node.js as a platform
          for backend applications. We covered topics like ES6 Fundamentals,
          Node.js ecosystem, Promises, Async Programming, HTTP APIs with
          Express.js, Testing and deploying with Docker.
        </p>
        <a href="http://wtmberlin.com/javascript-crash-course/">
          Curriculum example
        </a>

        <h3>Intern, 小赞武汉 Wuhan Social</h3>

        <p>
          I worked as an intern at a startup which is known as the number one
          source for expats in regards to places to go and events in Wuhan. I
          had various tasks which were designing web components for WeChat
          posts, assisting in the planning of social media campaigns and I was a
          contributing writer.
        </p>

        <h3>Visual Communications Design, Central China Normal University</h3>

        <p>
          I am often asked what a "normal university" means. Normal University
          refers to an institution that aimed to train school teachers in the
          early twentieth century. This terminology is preserved in the official
          names of such institutions in China even after these schools gained
          university status and expanded to offer courses beyond
          education-related fields. CCNU is ranked as a top 3 normal university
          in Mainland China.
        </p>
        <ul>
          <li>
            Challenging degree that was taught in Mandarin which helped me grow
            my interpersonal skills, work in a team and learn from others.
          </li>
          <li>
            I gained experience in industry-standard software such as Sketch,
            Adobe Creative Cloud applications (Illustrator, Photoshop,
            InDesign).
          </li>
          <li>
            We had projects that focused on visual design, UI design,
            interaction design, and product design.
          </li>
        </ul>

        <h3>
          {" "}
          <span role="img" aria-label="stacked books">
            📚
          </span>
          Self taught experience
        </h3>

        <p>
          I started learning how to code during my last year of university. It
          is challenging sometimes when you learn on your own as you don't
          always know if the technology you are learning is relevant. I have
          gotten better with filtering information as opposed to my "code
          newbie" stage
        </p>
        <ul>
          <li>
            I have been active on freecodecamp for a while, I have a Responsive
            Web Design certificate from Freecodecamp.
          </li>
          <li>Completed M220JS: MongoDB for Javascript Developers course</li>
          <li>
            Currently doing a Front-End Web Developer Nanodegree Program with
            Udacity alongside my coding bootcamp.
          </li>
          <li>
            I started a coding blog to share my learning progress. The blog
            itself is teaching me more about GatsbyJS.
          </li>
        </ul>
      </div> */}
      <div className="lineup"></div>

      {/* Languages */}

      <div className="locationbase">
        <p className="mainlocationtext">
          <ul>
            <li>English</li>
            <li>Setswana</li>
            <li>Intermediate Mandarin Chinese (HSK6)</li>
            <li>Basic German (A2)</li>
          </ul>
        </p>
        <div className="sidecol">
          <p>
            <b>Languages Spoken</b>
          </p>
          <img
            src="https://i1.lensdump.com/i/jISHMc.png"
            alt="chinese language"
          ></img>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { eq: false } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            rawDate: date
            path
          }
          fields {
            slug
            readingTime {
              text
            }
          }
          excerpt
        }
      }
    }
  }
`
